import React, { useState } from "react";
import "./contactUs.css";
import Header from "../../Components/Header/header";

export default function ContactUs() {
  return (
    <>
      <Header active="contact" />
      <div className="contactus-container">
        <div className="contact-main">
          <h2>CONTACT</h2>
        </div>
        <div className="contact-box">
          <div className="contact-box-title">Khanzada (Sydney)</div>
          <div className="contact-box-address">
            Level 24, International Tower 3, Barangaroo, NSW 2000
          </div>
          <div className="contact-box-tel">T: +61498000068 </div>
          <div className="contact-box-email">E: info@khanzada.com.au </div>
        </div>

        <div className="contact-us-form">
          <p>For commissions and inquiries, please leave us a message. </p>
          <div className="flex-row">
            <div className="formgroup">
              <div className="label">First Name</div>
              <input type="text" />
            </div>
            <div className="formgroup">
              <div className="label">Last Name</div>
              <input type="text" />
            </div>
          </div>
          <div className="formgroup">
            <div className="label">Email (required)</div>
            <input type="email" required />
          </div>
          <div className="formgroup">
            <div className="label">Subject (required)</div>
            <input type="text" required />
          </div>
          <div className="formgroup">
            <div className="label">Message (required)</div>
            <input type="text" required />
          </div>
          <button>Submit</button>
        </div>
      </div>
    </>
  );
}
