import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Screens/Home/home";
import AboutUs from "./Screens/About/about";
import Projects from "./Screens/Projects/projects";
import ProjectView from "./Screens/ProjectView/ProjectView";
import TeamScreen from "./Screens/Team/team";
import ContactUs from "./Screens/Contact/contactUs";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/about" Component={AboutUs} />
        <Route path="/projects" Component={Projects} />
        <Route path="/projects/:projectID" Component={ProjectView} />
        {/* <Route path="/team" Component={TeamScreen} /> */}
        <Route path="/contact" Component={ContactUs} />
      </Routes>
    </Router>
  );
}

export default App;
