import React, { useState } from "react";
import "./projects.css";
import ProjectCards from "../../Components/ProjectCards/projectCards";
import Header from "../../Components/Header/header";
import { data } from "../../Components//ProjectCards/Help/data";

export default function Projects() {
  console.log(data);
  return (
    <>
      <Header active="project" />
      <div className="project-container">
        {data.length
          ? data.map((val, i) => <ProjectCards data={val} key={val.id} />)
          : null}
      </div>
    </>
  );
}
