import React, { useState } from "react";
import "./projectView.css";
import ProjectCards from "../../Components/ProjectCards/projectCards";
import { useLocation } from "react-router-dom";
import Header from "../../Components/Header/header";
import { data } from "../../Components//ProjectCards/Help/data";

export default function ProjectView() {
  const location = useLocation();
  console.log(location.state);
  return (
    <>
      <Header />
      <div className="projectView-container">
        <div className="project-info">
          {/* <p className='project-main-descp'>
            A Corporate Workplace Design and Construction Project for
            multinational consumer products company - Colgate-Palmolive.
          </p>
          <p className='project-main-descp'>
            In early 2024, XLMS Limited, the main contractor, successfully
            completed a remarkable 26,000 sqft renovation project for Colgate
            Palmolive's Asia Headquarters in Hong Kong. The project aimed to
            transform the corporate workplace design and construction to meet
            the evolving needs of Colgate Palmolive.
          </p>
          <p className='project-main-descp'>
            Collaborating with design and branding consultants, Landor, the team
            achieved a stunning outcome that reflects the company's vision and
            enhances its presence in the region.
          </p>
          <p className='project-main-descp'>
            http://www.xlms.co/projects#/hong-kong-colgate-palmolive/
          </p> */}
        </div>

        <div className="project-content-container">
          <h1>{location.state.title}</h1>
          {location.state.images.map((val) => (
            <div className="project-image">
              <img src={val} />
            </div>
          ))}
        </div>
      </div>
      <div>
        <h2>Projects</h2>

        <div className="project-container">
          {data.length
            ? data.map((val, i) => <ProjectCards data={val} key={val.id} />)
            : null}
        </div>
      </div>
    </>
  );
}
