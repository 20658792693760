import React, { useState } from "react";
import "./home.css";
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
  FiYoutube,
} from "react-icons/fi";
import Header from "../../Components/Header/header";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigation = useNavigate();
  const onClickLink = (route) => {
    navigation(route);
  };
  return (
    <>
      <div className="home-container">
        <div className="flex-wrapper">
          <div className="left-side">
            <div></div>
            <iframe
              style={{ width: "100%", height: "100%" }}
              src="https://momento360.com/e/u/7858fb8497124b35bee80c529ce72608?utm_campaign=embed&utm_source=other&heading=48.6&pitch=-1.4&field-of-view=75&size=medium&display-plan=true"
              frameborder="0"
            ></iframe>
          </div>
          <div className="right-side">
            <div></div>
            <div className="right-side-content">
              <h1>Khanzada</h1>
              <h3>DESIGN & CONSTRUCTION</h3>
              <p>
                Khanzada is a dynamic firm specializing in architecture,
                interior design, construction management, and joinery
                fabrication. Their mission is to deliver innovative design
                solutions that are both commercially viable and tailored to
                local contexts.
              </p>
              <div className="link-sections">
                <div
                  onClick={() => onClickLink("/projects")}
                  className="link-btn"
                >
                  PROJECTS
                </div>
                <div onClick={() => onClickLink("/about")} className="link-btn">
                  ABOUT US
                </div>
                {/* <div onClick={onClickLink} className="link-btn">AWARDS</div> */}
                <div
                  onClick={() => onClickLink("/contact")}
                  className="link-btn"
                >
                  CONTACT US
                </div>
                {/* <div  className="link-btn">TEAM</div> */}
                <div className="socials-icons">
                  <FiFacebook className="sical-icon-name" />
                  <FiLinkedin className="sical-icon-name" />
                  <FiInstagram className="sical-icon-name" />
                  <FiTwitter className="sical-icon-name" />
                  <FiYoutube className="sical-icon-name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
