import React, { useState } from "react";

import "./header.css";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import { Button, Drawer } from "antd";
import { AiOutlineMenu } from "react-icons/ai";

export default function Header({ active }) {
  const [open, setOpen] = useState(false);
  const navigation = useNavigate();

  console.log(active);
  const onClickLink = (route) => {
    navigation(route);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="header-container">
        <div className="header-list">
          <div onClick={() => onClickLink("/")}>HOME</div>
          <div
            onClick={() => onClickLink("/projects")}
            className={window.location.pathname === "/projects" ? "active" : ""}
          >
            PROJECTS
          </div>
          <div
            onClick={() => onClickLink("/about")}
            className={window.location.pathname === "/about" ? "active" : ""}
          >
            ABOUT US
          </div>
          <div
            onClick={() => onClickLink("/contact")}
            className={window.location.pathname === "/contact" ? "active" : ""}
          >
            CONTACT US
          </div>
        </div>
        <div className="logo">
          <img src={require("../../Pics/logo.png")} />
        </div>
      </div>
      <div className="mobile-header">
        <div className="logo" style={{ width: 180, height: 180 }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={require("../../Pics/logo.png")}
          />
        </div>
        <AiOutlineMenu size={34} onClick={showDrawer} />
      </div>

      <Drawer placement="left" title="Khanzada" onClose={onClose} open={open}>
        <div className="drawer-list" onClick={() => onClickLink("/")}>
          HOME
        </div>
        <div className="drawer-list" onClick={() => onClickLink("/projects")}>
          PROJECTS
        </div>
        <div className="drawer-list" onClick={() => onClickLink("/about")}>
          ABOUT US
        </div>
        <div className="drawer-list" onClick={() => onClickLink("/contact")}>
          CONTACT US
        </div>
      </Drawer>
    </>
  );
}
