import React, { useState } from "react";
import "./about.css";
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiYoutube,
  FiMail,
} from "react-icons/fi";
import Header from "../../Components/Header/header";
const about1 = require("../../Pics/Assets/about-1.jpg");
const about2 = require("../../Pics/Assets/about-2.jpg");
export default function AboutUs() {
  return (
    <>
      <Header active="about" />
      <div className="about-container">
        <div className="about-info-section">
          <div className="left-section">
            <div className="about-main-title">DESIGN & CONSTRUCTION</div>
            <h1 className="quated-title">
              Khanzada is a large scale development, Design & Construction
              Company based in Australia.
            </h1>
            <p>
              Khanzada is a dynamic firm specializing in architecture, interior
              design, construction management, and joinery fabrication. Their
              mission is to deliver innovative design solutions that are both
              commercially viable and tailored to local contexts.
            </p>
            <p>
              With a focus on comprehensive turn-key solutions, Khanzada
              expertly navigates all phases of a project—pre-design, design, and
              construction—drawing on a wealth of experience across diverse
              construction services. Their extensive portfolio encompasses a
              wide range of sectors, including retail, workplace, hospitality,
              and luxury living, reflecting their commitment to developing
              practical and environmentally responsible designs that resonate
              with client aspirations.
            </p>
            <p>
              Khanzada's in-house expertise allows them to manage projects
              seamlessly from concept through to completion. They emphasize cost
              efficiency, quality, safety, and adherence to timelines, which
              enables them to streamline project costs and deliver
              value-engineered designs that ensure successful outcomes
              for every client.
            </p>
            <div className="left-side-image-container">
              <img src={about1} alt="" />
              <img src={about2} alt="" />
            </div>
            {/* <div className="link-section">
              <div className="about-link">
                Tianjin, New World Rosewood Hotel Suites
              </div>
              <span>[Project management]</span>
            </div> */}
            {/* <div className="list-section">
              <div className="sub-heading">HOSPITALITY PROJECTS</div>
              <div className="bullet-list-container">
                <li>
                  Hong Kong, Landmark Pop-Up F&B Stores for Hong Kong Land
                  [Construction]
                </li>
                <li>
                  Hong Kong, Landmark Pop-Up F&B Stores for Hong Kong Land
                  [Construction]
                </li>
                <li>
                  Hong Kong, Landmark Pop-Up F&B Stores for Hong Kong Land
                  [Construction]
                </li>
                <li>
                  Hong Kong, Landmark Pop-Up F&B Stores for Hong Kong Land
                  [Construction]
                </li>
                <li>
                  Hong Kong, Landmark Pop-Up F&B Stores for Hong Kong Land
                  [Construction]
                </li>
              </div>
            </div>
            <div className="list-section">
              <div className="sub-heading">RETAIL PROJECTS</div>
              <div className="bullet-list-container">
                <li>
                  Singapore, Paragon Orchard Road Alice + Olivia by Stacey
                  Bendet store [construction]
                </li>
                <li>
                  Xian, Yong’An Ge Yun Fang Retail Mall [Architecture Design]
                  190,000sqm
                </li>
                <li>
                  WF Central Beijing, Alice + Olivia by Stacey Bendet store
                  [construction]
                </li>
                <li>
                  WF Central Beijing, Tassels Gentlemen’s Luxury Store [design &
                  construction]
                </li>
                <li>
                  Tom Dixon Shop, Ap Lei Chau, 16-19, 11/F Horizon Plaza, 2 Lee
                  Wing Street [Construction] 2,170sqft.
                </li>
                <li>
                  One Central Macau, Popup at Shops 211&216 [Design & Build]
                </li>
                <li>Hong Kong, Landmark Wristcheck [construction]</li>
                <li>
                  Hong Kong, Landmark Below-ground and New Retails for Hong Kong
                  Land [Construction]
                </li>
              </div>
            </div>
            */}
          </div>
          <div className="right-section">
            {/* <div className="list-section">
              <div className="sub-heading">WORKPLACE PROJECTS</div>
              <p>
                XLMS workplace design prioritizes employee needs, fostering
                collaboration, learning, and mentorship. It cultivates a dynamic
                business culture, crucially attracting and retaining talent in
                today's economy.
              </p>
              <div className="bullet-list-container">
                <li>
                  Shenzhen, Chow Sang Sang Office [design & construction]
                  28,000sqft
                </li>
                <li>
                  Xian, Yong’An Ge Yun Fang Retail Mall [Architecture Design]
                  190,000sqm
                </li>
                <li>
                  WF Central Beijing, Alice + Olivia by Stacey Bendet store
                  [construction]
                </li>
                <li>
                  WF Central Beijing, Tassels Gentlemen’s Luxury Store [design &
                  construction]
                </li>
                <li>
                  Tom Dixon Shop, Ap Lei Chau, 16-19, 11/F Horizon Plaza, 2 Lee
                  Wing Street [Construction] 2,170sqft.
                </li>
                <li>
                  One Central Macau, Popup at Shops 211&216 [Design & Build]
                </li>
                <li>Hong Kong, Landmark Wristcheck [construction]</li>
                <li>
                  Hong Kong, Landmark Below-ground and New Retails for Hong Kong
                  Land [Construction]
                </li>
              </div>
            </div> */}
            {/* <div className="list-section">
              <div className="sub-heading">LUXURY LIVING PROJECTS</div>
              <p>
                For luxury living design, XLMS emphasizes a bespoke, holistic
                approach that leverages creativity, meticulous attention to
                detail, expertise in materials and finishes, effective
                collaboration, and access to exclusive resources. They aim to
                create premium residential spaces that are tailored to meet
                individual client needs and desires.
              </p>
              <div className="bullet-list-container">
                <li>
                  Apartment in Braemer Hill [design & construction) 800sqft
                </li>
                <li>
                  Xian, Yong’An Ge Yun Fang Retail Mall [Architecture Design]
                  190,000sqm
                </li>
                <li>
                  WF Central Beijing, Alice + Olivia by Stacey Bendet store
                  [construction]
                </li>
                <li>
                  WF Central Beijing, Tassels Gentlemen’s Luxury Store [design &
                  construction]
                </li>
                <li>
                  Tom Dixon Shop, Ap Lei Chau, 16-19, 11/F Horizon Plaza, 2 Lee
                  Wing Street [Construction] 2,170sqft.
                </li>
                <li>
                  One Central Macau, Popup at Shops 211&216 [Design & Build]
                </li>
                <li>Hong Kong, Landmark Wristcheck [construction]</li>
                <li>
                  Hong Kong, Landmark Below-ground and New Retails for Hong Kong
                  Land [Construction]
                </li>
              </div>
            </div> */}
            {/* <div className="list-section">
              <div className="sub-heading">HEALTH PROJECTS</div>

              <div className="bullet-list-container">
                <li>
                  Wan Chai, Bowtie & JP Medical [design & construction] 1,000
                  sqft
                </li>
              </div>
            </div> */}
            <div className="social-container">
              {/* <p>
                For more information please contact us on +852-3619-2139 or
                info@xlms.co
              </p> */}
              {/* <div className="socials-icons">
                <FiFacebook className="sical-icon-name" />
                <FiLinkedin className="sical-icon-name" />
                <FiInstagram className="sical-icon-name" />
                <FiMail className="sical-icon-name" />
                <FiYoutube className="sical-icon-name" />
              </div> */}

              {/* <div className="right-side-image-container">
                <img src={about2} alt="" />
              </div> */}
              {/* <p>
                Hong Kong, Thialh Luxury Jewellery Flagship Store Facade &
                Interior [Design & Build]
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
