import React, { useState } from "react";
import "./projectCards.css";
import { useNavigate } from "react-router-dom";

export default function ProjectCards({ data }) {
  const navigate = useNavigate();
  const onClickProject = () => {
    navigate(`/projects/:${data.id}`, { state: data });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div
      onClick={() => onClickProject()}
      className="project-card-container"
      style={{ backgroundImage: `url(${data.bgImg})` }}
    >
      <div className="bg-overlay">
        <div className="title">{data.title}</div>
        <span>-View-</span>
      </div>
    </div>
  );
}
