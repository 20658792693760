export const data = [
  {
    title: "Hill Side Villa",
    images: [
      require("../../../Pics/Assets/Hill Side Villa/0.jpg"),
      require("../../../Pics/Assets/Hill Side Villa/1.jpg"),
      require("../../../Pics/Assets/Hill Side Villa/2.jpg"),
      require("../../../Pics/Assets/Hill Side Villa/3.jpg"),
      require("../../../Pics/Assets/Hill Side Villa/4.jpg"),
      require("../../../Pics/Assets/Hill Side Villa/5.jpg"),
      require("../../../Pics/Assets/Hill Side Villa/6.jpg"),
      require("../../../Pics/Assets/Hill Side Villa/7.jpg"),
      require("../../../Pics/Assets/Hill Side Villa/8.jpg"),
      require("../../../Pics/Assets/Hill Side Villa/9.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/Hill Side Villa/0.jpg"),
    id: 1,
  },
  {
    title: "Paul Appartment",
    images: [
      require("../../../Pics/Assets/Paul Appartment/0.jpg"),
      require("../../../Pics/Assets/Paul Appartment/15.jpg"),
      require("../../../Pics/Assets/Paul Appartment/16.jpg"),
      require("../../../Pics/Assets/Paul Appartment/17.jpg"),
      require("../../../Pics/Assets/Paul Appartment/18.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/Paul Appartment/0.jpg"),
    id: 2,
  },
  {
    title: "Ma Man Appartment",
    images: [
      require("../../../Pics/Assets/Ma Man Apartment/13.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/14.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/15.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/16.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/17.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/18.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/19.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/20.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/21.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/22.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/23.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/24.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/25.jpg"),
      require("../../../Pics/Assets/Ma Man Apartment/26.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/Ma Man Apartment/13.jpg"),
    id: 3,
  },
  {
    title: "RESTAURANT 3D WITH MOOD BOARD",
    images: [
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/3.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/4.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/5.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/6.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/7.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/8.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/9.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/10.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/11.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/12.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/13.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/14.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/15.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/16.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/17.jpg"),
      require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/18.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/RESTAURANT 3D WITH MOOD BOARD/3.jpg"),
    id: 4,
  },
  {
    title: "CAFE DESIGN MOOD BOARD",
    images: [
      require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/3.jpg"),
      require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/4.jpg"),
      require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/5.jpg"),
      require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/6.jpg"),
      require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/7.jpg"),
      require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/8.jpg"),
      require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/9.jpg"),
      require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/10.jpg"),
      require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/11.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/CAFE DESIGN MOOD BOARD/2.jpg"),
    id: 5,
  },
  {
    title: "CABI CAFE",
    images: [
      require("../../../Pics/Assets/CABI CAFE/3.jpg"),
      require("../../../Pics/Assets/CABI CAFE/4.jpg"),
      require("../../../Pics/Assets/CABI CAFE/5.jpg"),
      require("../../../Pics/Assets/CABI CAFE/6.jpg"),
      require("../../../Pics/Assets/CABI CAFE/7.jpg"),
      require("../../../Pics/Assets/CABI CAFE/8.jpg"),
      require("../../../Pics/Assets/CABI CAFE/9.jpg"),
      require("../../../Pics/Assets/CABI CAFE/10.jpg"),
      require("../../../Pics/Assets/CABI CAFE/11.jpg"),
      require("../../../Pics/Assets/CABI CAFE/12.jpg"),
      require("../../../Pics/Assets/CABI CAFE/13.jpg"),
      require("../../../Pics/Assets/CABI CAFE/14.jpg"),
      require("../../../Pics/Assets/CABI CAFE/15.jpg"),
      require("../../../Pics/Assets/CABI CAFE/16.jpg"),
      require("../../../Pics/Assets/CABI CAFE/17.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/CABI CAFE/2.jpg"),
    id: 6,
  },
  {
    title: "8OZ INTERIOR",
    images: [
      require("../../../Pics/Assets/8OZ INTERIOR/4.jpg"),
      require("../../../Pics/Assets/8OZ INTERIOR/5.jpg"),
      require("../../../Pics/Assets/8OZ INTERIOR/6.jpg"),
      require("../../../Pics/Assets/8OZ INTERIOR/7.jpg"),
      require("../../../Pics/Assets/8OZ INTERIOR/8.jpg"),
      require("../../../Pics/Assets/8OZ INTERIOR/9.jpg"),
      require("../../../Pics/Assets/8OZ INTERIOR/10.jpg"),
      require("../../../Pics/Assets/8OZ INTERIOR/11.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/8OZ INTERIOR/4.jpg"),
    id: 7,
  },
  {
    title: "Exterior Design",
    images: [
      require("../../../Pics/Assets/EXTERIOR DESIGN/0.jpg"),
      require("../../../Pics/Assets/EXTERIOR DESIGN/1.jpg"),
      require("../../../Pics/Assets/EXTERIOR DESIGN/2.jpg"),
      require("../../../Pics/Assets/EXTERIOR DESIGN/3.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/EXTERIOR DESIGN/0.jpg"),
    id: 8,
  },
  {
    title: "COURTYARD",
    images: [
      require("../../../Pics/Assets/COURTYARD/0.jpg"),
      require("../../../Pics/Assets/COURTYARD/1.jpg"),
      require("../../../Pics/Assets/COURTYARD/2.jpg"),
      require("../../../Pics/Assets/COURTYARD/3.jpg"),
      require("../../../Pics/Assets/COURTYARD/4.jpg"),
      require("../../../Pics/Assets/COURTYARD/5.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/COURTYARD/0.jpg"),
    id: 9,
  },
  {
    title: "FRONTYARD",
    images: [
      require("../../../Pics/Assets/FRONTYARD/0.jpg"),
      require("../../../Pics/Assets/FRONTYARD/1.jpg"),
      require("../../../Pics/Assets/FRONTYARD/2.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/FRONTYARD/0.jpg"),
    id: 10,
  },
  {
    title: "BACKYARD BINDER",
    images: [
      require("../../../Pics/Assets/BACKYARD BINDER/0.jpg"),
      require("../../../Pics/Assets/BACKYARD BINDER/1.jpg"),
      require("../../../Pics/Assets/BACKYARD BINDER/2.jpg"),
      require("../../../Pics/Assets/BACKYARD BINDER/3.jpg"),
      require("../../../Pics/Assets/BACKYARD BINDER/4.jpg"),
      require("../../../Pics/Assets/BACKYARD BINDER/5.jpg"),
    ],
    bgImg: require("../../../Pics/Assets/BACKYARD BINDER/0.jpg"),
    id: 11,
  },
];
